/** @jsxImportSource @emotion/react */
import { Column, FCC, Paragraph, Row, Transition } from '@linen/ui';
import DancingDots, { Floaty } from '../DancingDots/DancingDots';
import { sizeAlert } from './SizeAlert.styles';

const SizeAlert: FCC = () => {
  return (
    <Column pad justify='center' align='center' css={sizeAlert}>
      <Transition effect={'fade-in'} speed={'slower'} init={true} appear>
        <DancingDots colors={['#ffd269', '#6984db', '#119c5d']}>
          <Column align='center' justify='center' gap={16}>
            <Row gap={4} wide justify='center'>
              <Floaty>
                <Paragraph xl black style={{ zIndex: 50 }}>
                  Your
                </Paragraph>
              </Floaty>
              <Floaty>
                <Paragraph xl black style={{ zIndex: 50 }}>
                  Screen
                </Paragraph>
              </Floaty>
            </Row>
            <Row gap={4} wide justify='center'>
              <Floaty>
                <Paragraph xl black style={{ zIndex: 50 }}>
                  Is
                </Paragraph>
              </Floaty>
              <Floaty>
                <Paragraph xl black style={{ zIndex: 50 }}>
                  Too
                </Paragraph>
              </Floaty>
              <Floaty>
                <Paragraph xl black style={{ zIndex: 50 }}>
                  Small
                </Paragraph>
              </Floaty>
            </Row>
          </Column>
        </DancingDots>
      </Transition>
    </Column>
  );
};

export default SizeAlert;
