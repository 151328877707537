import React, { ReactNode } from 'react';
import { PrettyLinkDef } from '../Components/PrettyLink/PrettyLink';
// import ColorBlocks from '../Pages/ColorBlocks';
// import ComponentLibrary from '../Pages/ComponentLibrary';
// import FirstFreight from '../Pages/FirstFreight';
// import HighArt from '../Pages/HighArt';
// import Music from '../Pages/Music';
// import NativEscrows from '../Pages/NativEscrows';
// import NativTransactions from '../Pages/NativTransactions';
const ColorBlocks = React.lazy(() => import('../Pages/ColorBlocks'));
const ComponentLibrary = React.lazy(() => import('../Pages/ComponentLibrary'));
const FirstFreight = React.lazy(() => import('../Pages/FirstFreight'));
const HighArt = React.lazy(() => import('../Pages/HighArt'));
const Music = React.lazy(() => import('../Pages/Music'));
const NativEscrows = React.lazy(() => import('../Pages/NativEscrows'));
const NativTransactions = React.lazy(
  () => import('../Pages/NativTransactions')
);

export interface RouteDef {
  path: string;
  title: string;
  label?: string;
  render: ReactNode;
  exact?: boolean;
  subtitle?: string;
  description?: string;
  hide?: boolean;
}

export const allRoutes: RouteDef[] = [
  {
    path: '/nativ-transactions',
    exact: true,
    render: <NativTransactions />,
    label: 'Servicer Integration',
    subtitle: 'Software Engineer, Nativ',
    title: 'Servicer Integration',
    description:
      'Wireframes, User Flow, UI Design, React/TS + Node/Express Development',
  },
  {
    path: '/nativ-escrows',
    exact: true,
    render: <NativEscrows />,
    title: 'Escrows',
    subtitle: 'Software Engineer, Nativ',
    description: 'UI Design, React/TS Development, Technical Diagrams',
  },
  {
    path: '/high-art',
    exact: true,
    render: <HighArt />,
    title: 'Fine Art Services Application',
    subtitle: 'Designer/Developer, High Art',
    description:
      'UX Research, Sketches, UI Design, React + Redux + Rails Development',
  },
  {
    path: '/first-freight',
    exact: true,
    render: <FirstFreight />,
    title: 'Branding + App Design',
    subtitle: 'Contract Designer at First Frieght',
    description:
      'Brand Development, Logo Design, Web Design, Hi-Fidelity Prototyping',
  },
  {
    path: '/color-blocks',
    exact: true,
    render: <ColorBlocks />,
    title: 'Color Blocks',
    subtitle: 'Personal Project',
    hide: true,
    description:
      'User Flow, Wireframes, UI Design, React/TS + Node/Express Development',
  },
  {
    path: '/component-library',
    exact: true,
    render: <ComponentLibrary />,
    title: 'Component Library',
    subtitle: 'Personal Project, Open Source',
    description:
      'User Flow, Wireframes, UI Design, React/TS + Node/Express Development',
    hide: true,
  },
  {
    path: '/music',
    exact: true,
    render: <Music />,
    title: 'Bedroom Pop',
    subtitle: 'Solo Artist',
    description: 'Songs, Music, Sounds, Aural Ephemera, Album Artwork',
  },
];

export const routes = allRoutes.filter((r) => !r.hide);

export const links: PrettyLinkDef[] = routes
  .filter((r) => !r.hide)
  .map((route) => ({
    to: route.path,
    label: route.label ?? route.title,
    description: route.description,
    subtitle: route.subtitle,
  }));
