import { css } from '@emotion/react';
import { colors, transitionIn, transitionOut } from '../../Styles/styles';

export const prettyLink = css`
  width: 100%;
  padding: 16px 32px;
  display: flex;
  gap: 0px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${colors.white};
  color: ${colors.black};
  transition: ${transitionOut};
  &:hover {
    background: ${colors.gray};
    transition: ${transitionIn};
  }
  &:active,
  &.--active {
    background: ${colors.black};
    color: ${colors.white};
    transition: ${transitionIn};
    .pretty-link__description {
      color: ${colors.white60};
      transition: ${transitionIn};
    }
  }
  .pretty-link {
    &__outer {
      gap: 4px;
    }
    &__inner {
      gap: 0px;
      padding-left: 20px;
    }
    &__description {
      margin-top: 4px;
      font-size: 0.75rem;
      line-height: 1.5;
      color: ${colors.black80};
      transition: ${transitionOut};
    }
    &__subtitle {
      line-height: 1.7;
      font-size: 0.875rem;
    }
    &__index {
      font-variant-numeric: tabular-nums;
    }
  }
  border-bottom: 1px solid ${colors.black80};
  &:last-child {
    border-bottom: none;
  }
  &.big-label {
    padding: 32px;
    padding-top: 20px;
    position: sticky;
    top: 0;
    .pretty-link__label {
      font-size: 32px;
      font-weight: 800;
      line-height: 40px;
    }
  }
`;
