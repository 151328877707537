export const getRandom = (max?: number, min?: number) => {
	max = max || 100;
	min = min || 0;
	return Math.floor(Math.random() * (max - min + 1) + min);
};

export const randomPosNeg = (num: number) => {
	let decider = getRandom();
	if (decider > 50) {
		return Math.abs(num);
	} else if (decider < 50) {
		return Math.abs(num) * -1;
	} else {
		return num;
	}
};
