/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FCC } from '@linen/ui';
import { getRandom } from '../../Util/getRandom';
import {
  dance,
  dotBase,
  dotsContainer,
  floaty,
  sparkles,
} from './DancingDots.styles';

export interface DancingDotsProps {
  dots?: number;
  colors?: string[];
  SPARKLES?: boolean;
}

const delays = [
  0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5,
  1.6, 1.7, 1.8, 1.9, 2,
];

const durations = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(
  (d) => d * 2
);

export const getFloaty = (max?: number, min?: number) => {
  let digs = [
    getRandom(max, min),
    getRandom(max, min),
    getRandom(max, min),
    getRandom(max, min),
    getRandom(max, min),
    getRandom(max, min),
  ];
  const [x1, x2, x3, y1, y2, y3] = digs;
  const coords = { x1, x2, x3, y1, y2, y3 };
  const size = getRandom(12, 4);
  const duration = durations[getRandom(durations.length)];
  const delay = delays[getRandom(delays.length)];
  return { coords, size, duration, delay };
};

const DancingDots: FCC<DancingDotsProps> = (props) => {
  const dotCount = props.dots || 99;

  const dotMap = () => {
    const dots: any[] = [];
    for (let i = 0; i < dotCount; i++) {
      let floaty = getFloaty();

      const color = props.colors
        ? props.colors[getRandom(props.colors.length)]
        : '#333';
      dots.push({ ...floaty, color });
    }
    return dots.map((dot) => (
      <Dot
        dot={dot}
        key={`${dot.x}-${dot.y}-${dot.size}-${dot.duration}-${dot.delay}`}
        styles={css`
          animation-name: ${props.SPARKLES
            ? sparkles()
            : dance(dot.coords, ['vw', 'vh'])};
        `}
      />
    ));
  };
  return (
    <div css={dotsContainer} className='__cl-dancing-dots cl-dancing-dots'>
      {dotMap()}
      {props.children}
    </div>
  );
};

export default DancingDots;

export interface DotProps {
  styles: any;
  dot: any;
}

export const Dot: FCC<DotProps> = (props) => {
  return <div className='dot' css={[dotBase(props.dot), props.styles]} />;
};

export interface FloatyProps {
  max?: number;
  min?: number;
}

export const Floaty: FCC<FloatyProps> = (props) => {
  return (
    <div
      css={floaty(getFloaty(props.max || 80, props.min || 0))}
      className='cl-floaty __cl-floaty'
    >
      {props.children}
    </div>
  );
};
