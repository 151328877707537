import { css, keyframes } from '@emotion/react';
import { getRandom, randomPosNeg } from '../../Util/getRandom';

export const dotsContainer = css`
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const floaty = (dot: any) => css`
	background: ${dot.color};
	position: relative;
	animation-iteration-count: infinite;
	animation-duration: ${dot.duration}s;
	animation-timing-function: cubic-bezier(0.35, 0.27, 0.73, 0.87);
	z-index: ${getRandom()};
	animation-delay: 0s;
	animation-name: ${move(dot.coords, ['px', 'px'])};
`;

export const move = (
	coords: Record<string, number>,
	units: ['vw' | 'px' | '%', 'vh' | 'px' | '%']
) => {
	const [w, h] = units;
	const x1 = randomPosNeg(coords.x1);
	const y1 = randomPosNeg(coords.y1);
	const x2 = randomPosNeg(coords.x2);
	const y2 = randomPosNeg(coords.y2);

	return keyframes`
	0% {
		transform: rotate(0deg) translate(0,0);
	}
	33% {
		transform: rotate(${randomPosNeg(
			getRandom(15, 4)
		)}deg) translate(${x1}${w}, ${y1}${h});
	}
	66% {
		transform: rotate(${randomPosNeg(
			getRandom(15, 4)
		)}deg) translate(${x2}${w}, ${y2}${h});
	}
	100% {
		transform: rotate(0deg) translate(0,0);
	}
`;
};

export const dotBase = (dot: any) => css`
	background: ${dot.color};
	height: ${dot.size}px;
	width: ${dot.size}px;
	border-radius: 1000px;
	position: fixed;
	top: ${dot.coords.y1}vh;
	opacity: ${1 - Math.random() * 0.4};
	left: ${dot.coords.x1}vw;
	animation-iteration-count: infinite;
	animation-duration: ${dot.duration}s;
	animation-timing-function: cubic-bezier(0.35, 0.27, 0.73, 0.87);
	z-index: ${getRandom()};
	animation-delay: 0s;
`;

export const dance = (
	coords: Record<string, number>,
	units: ['vw' | 'px' | '%', 'vh' | 'px' | '%']
) => {
	const [w, h] = units;
	return keyframes`
	0% {
		top: ${coords.y1}${h};
		left: ${coords.x1}${w};
	}
	33% {
		top: ${coords.y2}${h};
		left: ${coords.x2}${w};
	}
	66% {
		top: ${coords.y3}${h};
		left: ${coords.x3}${w};
	}
	100% {
		top: ${coords.y1}${h};
		left: ${coords.x1}${w};
	}
`;
};

export const sparkles = () => {
	const x = getRandom();
	const y = getRandom();
	return keyframes`
	0% {
		top: ${y}vh;
		left: ${x}vw;
	}
	50% {
		top: ${getRandom()}vh;
		left: ${getRandom()}vw;
	}
	100% {
		top: ${y}vh;
		left: ${x}vw;
	}
`;
};
