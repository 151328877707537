/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Button,
  Column,
  FCC,
  Grid,
  Heading,
  Image,
  Paragraph,
  cn,
} from '@linen/ui';

export interface WorkSectionHeaderProps {
  heading: string;
  subheading?: string;
  date?: string;
  image: string;
  imageAlt: string;
  link?: string;
  linkHref?: string;
  reverse?: boolean;
  aspectRatio?: string;
  padding?: string;
  className?: string;
}

const WorkSectionHeader: FCC<WorkSectionHeaderProps> = (props) => {
  return (
    <div className={cn(['work-section-header', props.className])}>
      <Grid columns={2} gap='0px'>
        <Column
          style={{
            gridColumn: props.reverse ? 2 : 1,
          }}
          justify='start'
          align='center'
          pad
          className={cn([
            props.reverse ? 'b' : 'a',
            'work-section-header-content-container',
          ])}
        >
          <Column wide gap={20}>
            <Column wide gap={4}>
              <Heading>{props.heading}</Heading>
              {props.subheading && (
                <Paragraph large bold>
                  {props.subheading}
                </Paragraph>
              )}
              {props.date && <Paragraph small>{props.date}</Paragraph>}
            </Column>
            {props.children}
            {props.linkHref && props.link && (
              <a
                href={props.linkHref}
                rel='noopener noreferrer'
                target='_blank'
              >
                <Button link icon='external-link-alt' iconSide='right'>
                  {props.link}
                </Button>
              </a>
            )}
          </Column>
        </Column>
        <Column
          justify='center'
          align='center'
          pad
          className={cn([
            props.reverse ? 'a' : 'b',
            'work-section-header-image-container',
          ])}
          style={{
            gridColumn: props.reverse ? 1 : 2,
          }}
          css={css`
            padding: 0 !important;
          `}
        >
          <Image
            css={css`
              aspect-ratio: ${props.aspectRatio || '8 / 5'};
            `}
            alt={props.imageAlt}
            src={props.image}
            clickOpensLightbox
          />
        </Column>
      </Grid>
    </div>
  );
};

export default WorkSectionHeader;
