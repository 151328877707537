import { css } from '@emotion/react';

export const colors = {
	white: '#fff',
	black: 'rgba(51,51,50,1)',
	black10: 'rgba(51,51,50,.1)',
	gray: '#ebebeb',
	white60: 'rgba(255,255,255.6)',
	black80: 'rgba(51,51,50,.8)',
};

export const transitionIn = '.2s ease-in';
export const transitionOut = '.1s ease';

export const sideNav = css`
	height: 100%;
	overflow: hidden;
`;
