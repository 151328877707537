import { css } from '@emotion/react';

export const sizeAlert = css`
	height: 100vh;
	width: 100vw;
	text-align: center;
	.__paragraph {
		font-size: 32px;
		font-weight: 900;
	}
`;
