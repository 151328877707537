import { FC } from 'react';
import {
  Anchor,
  Column,
  Divider,
  List,
  Page,
  Paragraph,
  Text,
} from '@linen/ui';
import WorkSectionHeader from '../Components/WorkSection/WorkSectionHeader';

const Home: FC = () => {
  return (
    <Page title='Jackson Hardy' id='home'>
      <Column>
        <WorkSectionHeader
          heading='Hey'
          image={'/jh.jpg'}
          className='bb'
          aspectRatio='6 / 9'
          padding='40px'
          imageAlt='pic of me, Jack Hardy'
        >
          <Paragraph>
            I'm a software engineer with a background in design and a passion
            for making silly things.
          </Paragraph>
          <Paragraph>
            Here you'll find a selection of some of my recent(ish) work. Most of
            what I've chosen to include is work where I had the opportunity to
            cover both the design and development. A few of the items will show
            details of one narrowly-defined project, and others will cover a
            bigger endeavor. All of the data in the live images is 'dummy data',
            same goes for designs and mocks. For the sake of quality, where
            possible, some of image files are pretty hefty, even after
            compression. And yes, my handwriting really is that bad.
          </Paragraph>
          <Paragraph>
            If you'd like to see more of my work, or if you'd like to get in
            touch, you can find me on{' '}
            <Anchor href='https://www.linkedin.com/in/jacksonhardy/'>
              LinkedIn
            </Anchor>
            , or you can email me at{' '}
            <Anchor href='mailto:jacksonrhardy@gmail.com'>
              jacksonrhardy[at]gmail.com
            </Anchor>
            .
          </Paragraph>
          <Divider dotted />
          <Paragraph>Here are a few other lil' things I made:</Paragraph>
          <List
            items={[
              <Anchor
                href='https://colorblocks.ooo'
                isExternal
                target='_blank'
                rel='noopener noreferrer'
              >
                Color Blocks
              </Anchor>,
              <Anchor
                href='https://randomimagegen.com'
                isExternal
                target='_blank'
                rel='noopener noreferrer'
              >
                Random Image Generator
              </Anchor>,
              <Anchor
                href='https://jackson-makes-a-synth.web.app/'
                isExternal
                target='_blank'
                rel='noopener noreferrer'
              >
                Synth{' '}
                <Text small>
                  (careful with the delay, it will break things...)
                </Text>
              </Anchor>,
              <Anchor
                href='https://linen-ui.web.app/'
                isExternal
                target='_blank'
                rel='noopener noreferrer'
              >
                Linen UI Component Library
                <Text small>(very in progress, very beta)</Text>
              </Anchor>,
            ]}
          />
        </WorkSectionHeader>
      </Column>
    </Page>
  );
};

export default Home;
