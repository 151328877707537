/** @jsxImportSource @emotion/react */
import { Column, FCC, Paragraph, Text, cn } from '@linen/ui';
import { Link, useLocation } from 'react-router-dom';
import { prettyLink } from './PrettyLink.styles';

export interface PrettyLinkDef {
  to: string;
  label: string;
  description?: string;
  subtitle?: string;
}
export interface PrettyLinkProps {
  link: PrettyLinkDef;
  index: number;
  className?: string;
}

export const PrettyLink: FCC<PrettyLinkProps> = (props) => {
  const link = props.link;
  const loc = useLocation();

  return (
    <Link
      to={link.to}
      css={prettyLink}
      className={cn([
        'pretty-link',
        props.className,
        loc.pathname === link.to && '--active',
      ])}
    >
      <Column wide className='pretty-link__outer'>
        <Text small black className='pretty-link__index'>
          0{props.index}
        </Text>
        <Column className='pretty-link__inner' wide>
          <Paragraph black className='pretty-link__label'>
            {props.children || link.label}
          </Paragraph>
          {link.subtitle && (
            <Paragraph className='pretty-link__subtitle'>
              {link.subtitle}
            </Paragraph>
          )}
          {link.description && (
            <Paragraph small className='pretty-link__description'>
              {link.description}
            </Paragraph>
          )}
        </Column>
      </Column>
    </Link>
  );
};
