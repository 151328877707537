/** @jsxImportSource @emotion/react */
import {
  Content,
  KineticLoader,
  Layout,
  Page,
  SideNav,
  Sidebar,
  useBreakpoints,
  useScrollRestoration,
} from '@linen/ui';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { PrettyLink } from './Components/PrettyLink/PrettyLink';
import SizeAlert from './Components/SizeAlert/SizeAlert';
import FauxAuth from './FauxAuth/FauxAuth';
import Home from './Pages/Home';
import { sideNav } from './Styles/styles';
import { links, routes } from './Util/routes';
import { Suspense, useTransition } from 'react';

const Main = () => {
  const bp = useBreakpoints();
  let location = useLocation();
  useScrollRestoration();
  const [isPending, startTransition] = useTransition();
  return (
    <>
      {bp > 960 ? (
        <Layout
          layout='sidebar-content'
          Sidebar={
            <Sidebar>
              <SideNav css={sideNav}>
                <PrettyLink
                  link={{ to: '/', label: 'Jackson Hardy' }}
                  className='big-label'
                  index={0}
                >
                  Jackson <br />
                  Hardy
                </PrettyLink>

                {links.map((link, i) => (
                  <PrettyLink key={link.to} link={link} index={i + 1} />
                ))}
              </SideNav>
            </Sidebar>
          }
          Content={
            <Suspense fallback={<KineticLoader />}>
              <Content>
                <Routes>
                  <Route path='/' element={<Home />} />
                  {routes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<Page title={route.title}>{route.render}</Page>}
                    />
                  ))}
                </Routes>
              </Content>
            </Suspense>
          }
        />
      ) : (
        <SizeAlert />
      )}
    </>
  );
};

export default Main;
